import React from "react";
import * as moment from "moment";
import { Card, CardContent } from "@material-ui/core";
import PersistentDrawerRight from "../navBar/nav";
import { sendHttpRequest, BASE_URL, checkIfUserLoggedIn } from "../../common/Common";
import Carousel from "react-elastic-carousel";
import { toast } from "react-toastify";
import NoResults from "../NoResults";
import Match from "../match/Match";
import './HomeScreen.css'

class HomeScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      matches: []
    };
  }
  componentDidMount() {
    checkIfUserLoggedIn(this.props.history);
    this.getUserDetails();
    this.getUpcomingOngoingMatches();
  }

  getUserDetails() {
    sendHttpRequest("GET", BASE_URL + "/api/player/" + localStorage.getItem("loggedInUserId")).then((res) => {
      this.setState({
        name: res.data.data.firstName + " " + res.data.data.lastName
      });
    }).catch((error) => {
      toast.error(error.response.data.message)
    });
  }

  getUpcomingOngoingMatches() {
    sendHttpRequest("GET", BASE_URL + "/api/match/user/" + localStorage.getItem('loggedInUserId')).then((res) => {
      this.setState({
        matches: res.data.data.filter((match) => match.status === "STARTED" || match.status === "NOT-STARTED")
      });
    }).catch((error) => {
      toast.error(error.response.data.message)
    });
  }

  render() {
    const { name, matches } = this.state;

    return (
      <div>
        <PersistentDrawerRight title="Home" />
        <div className="page-wrapper">
          <h2 className="text-blue-bg my-0">Welcome! {name}</h2>
          <div className="flex-between mb-15" >
            <h3 className="text-white-bg">Upcoming & ongoing matches</h3>
            <h3 className="text-white-bg">{moment(new Date()).format("DD-MM-YYYY")}</h3>
          </div>
          {matches?.length === 0 ? (
            <NoResults text="matches" />
          ) : (
            <Carousel autoPlaySpeed={2000} showArrows={false} itemPadding={[0, 1]}>
              {matches.map((match, index) => (
                <Match data={match} key={index} />
              ))}
            </Carousel>
          )}
          <div>
            <h3 className="text-white-bg"> Profile Related</h3>
            <Card className="card" onClick={() => { alert("Coming Soon") }}>
              <CardContent>
                <h2 className="text-primary text-center">Last Session details</h2>
              </CardContent>
            </Card>
            <Card className="card" onClick={() => { alert("Coming Soon") }}>
              <CardContent>
                <h2 className="text-primary text-center">Overall Statistics</h2>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    );
  }
}

export default HomeScreen;
