import React from "react";
import { Card, CardContent, CircularProgress } from "@material-ui/core";
import { sendHttpRequest, BASE_URL, isEmail, isContactNo } from "../../common/Common";
import CustomTextField from "../CustomMUI/CustomTextField";
import { toast } from "react-toastify";
import PersistentDrawerRight from "../navBar/nav";
import { PrimaryButton } from "../CustomMUI/CustomButtons";

class Register extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fields: {
        firstname: "",
        lastname: "",
        email: "",
        contactNo: "",
        password: "",
        confirmPassword: "",
        isLoading: false
      },
    };

    this.handleRegister = this.handleRegister.bind(this);
  }

  handleRegister() {
    const { firstname, lastname, email, contactNo, password, confirmPassword } = this.state;

    if (!firstname) {
      toast.error("Please Enter First Name")
      return
    }
    if (!lastname) {
      toast.error("Please Enter Last Name")
      return
    }
    if (!isEmail(email)) {
      toast.error("Please Enter Valid Email Address")
      return
    }
    if (!isContactNo(contactNo)) {
      toast.error("Please Enter Valid contact number")
      return
    }
    if (!password) {
      toast.error("Password can't be empty.")
      return
    }
    if (!confirmPassword) {
      toast.error("Confirm Password can't be empty")
      return
    }
    if (password !== confirmPassword) {
      toast.error("Passwords Don't Match")
      return
    }

    const data = {
      firstName: firstname,
      lastName: lastname,
      email: email,
      contactNo: contactNo,
      password: password,
    };

    this.setState({ isLoading: true })
    
    sendHttpRequest("POST", BASE_URL + "/api/auth/register", null, JSON.stringify(data)).then((res) => {
      this.setState({ isLoading: false })
      toast.success(res.data.message)
      this.props.history.push("/")
    }).catch((error) => {
      this.setState({ isLoading: false })
      toast.error(error.response.data.message)
    })
  }

  render() {
    const { firstname, lastname, email, contactNo, password, confirmPassword, isLoading } = this.state;

    return (
      <div>
        <PersistentDrawerRight title={'Register'} farLeft={true} hideMenu />
        <div className="page-wrapper">
          <Card className="card">
            <CardContent>
              <CustomTextField
                label="First Name"
                type="name"
                value={firstname}
                onChange={(e) => this.setState({ firstname: e.target.value })}
              />
              <CustomTextField
                label="Last Name"
                type="name"
                value={lastname}
                onChange={(e) => this.setState({ lastname: e.target.value })}
              />
              <CustomTextField
                label="Email"
                type="email"
                value={email}
                onChange={(e) => this.setState({ email: e.target.value })}
              />
              <CustomTextField
                label="Contact number"
                type="tel"
                value={contactNo}
                onChange={(e) => this.setState({ contactNo: e.target.value })}
              />
              <CustomTextField
                label="Password"
                type="password"
                value={password}
                onChange={(e) => this.setState({ password: e.target.value })}
              />
              <CustomTextField
                label="Confirm Password"
                type="password"
                value={confirmPassword}
                onChange={(e) => this.setState({ confirmPassword: e.target.value })}
              />
              <div className="text-center mt-15">
                <PrimaryButton
                  onClick={this.handleRegister}
                >
                  {isLoading ? <CircularProgress /> : "Register"}
                </PrimaryButton>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    );
  }
}

export default Register;