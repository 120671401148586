import React from "react";
import { FormControl, MenuItem, Radio, FormControlLabel, RadioGroup, FormLabel, CircularProgress } from "@material-ui/core";
import { toast } from "react-toastify";
import { sendHttpRequest, BASE_URL, checkIfUserLoggedIn } from "../../common/Common";
import CustomTextField from "../CustomMUI/CustomTextField";
import { PrimaryButton, SecondaryButton } from "../CustomMUI/CustomButtons";

class CreateTournamentModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      name: "",
      date: "",
      ground: "",
      ballType: "",
      overs: 0,
      pitchType: "",
      tournamentType: "",
      teamCount: 0
    };
    this.createTournament = this.createTournament.bind(this);
  }

  componentDidMount() {
    checkIfUserLoggedIn(this.props.history);
  }

  createTournament() {
    const { name, ground, date, ballType, overs, pitchType, tournamentType, teamCount } = this.state;

    if (!name) {
      toast.error("Enter tournament name");
      return;
    }
    if (!ground) {
      toast.error("Enter a ground");
      return;
    }
    if (!date) {
      toast.error("Select a tournament date");
      return;
    }
    if (!ballType) {
      toast.error("Select a ball type");
      return;
    }
    if (teamCount === 0) {
      toast.error("Team count cannot be 0");
      return;
    }
    if (overs === 0) {
      toast.error("Over count cannot be 0");
      return;
    }
    if (!pitchType) {
      toast.error("Select a pitch type");
      return;
    }
    if (!tournamentType) {
      toast.error("Select a tournament type");
      return;
    }

    let data = {
      name: name,
      ground: ground,
      date: date,
      ballType: ballType,
      overs: overs,
      pitchType: pitchType,
      teamCount: teamCount,
      tournamentType: tournamentType,
      createdBy: localStorage.getItem("loggedInUserId")
    }

    this.setState({ isLoading: true });
    sendHttpRequest("POST", BASE_URL + "/api/tournament/", null, JSON.stringify(data)).then((res) => {
      toast.success(res.data.message)
      this.props.onClose()
      this.props.reloadTournaments()
    }).catch((error) => {
      toast.error(error?.response?.data?.message)
    }).finally(() => {
      this.setState({ isLoading: false })
    });
  }

  render() {
    const { name, date, ground, ballType, overs, pitchType, teamCount, tournamentType, isLoading } = this.state;
    const ballTypes = ["Tennis Ball", "Red Ball", "White Ball", "Pink Ball"]
    const pitchTypes = ["Indoor", "Matting", "Astra", "Turf", "Grass"]

    return (
      <div>
        <h2 align="center" className='text-primary'>Create Tournament</h2>
        <CustomTextField
          label="Tournament Name"
          type="text"
          value={name}
          onChange={(e) => this.setState({ name: e.target.value })}
        />
        <CustomTextField
          type="date"
          value={date}
          onChange={(e) => this.setState({ date: e.target.value })}
        />
        <CustomTextField
          label="Ground"
          type="text"
          value={ground}
          onChange={(e) => this.setState({ ground: e.target.value })}
        />
        <div className="flex-between">
          <CustomTextField
            select
            label="Ball type"
            className="me-15"
            value={ballType}
            onChange={(e) => this.setState({ ballType: e.target.value })}
          >
            {ballTypes.map((ballType, index) => (
              <MenuItem key={index} value={ballType}>{ballType}</MenuItem>
            ))}
          </CustomTextField>
          <CustomTextField
            label="No. of overs"
            type="number"
            InputProps={{ inputProps: { min: 1, max: 50 } }}
            value={overs}
            onChange={(e) => this.setState({ overs: e.target.value })}
          />
        </div>
        <div className="flex-between">
          <CustomTextField
            select
            label="Pitch type"
            className="me-15"
            value={pitchType}
            onChange={(e) => this.setState({ pitchType: e.target.value })}
          >
            {pitchTypes.map((pitchType, index) => (
              <MenuItem key={index} value={pitchType}>{pitchType}</MenuItem>
            ))}
          </CustomTextField>
          <CustomTextField
            label="No. of teams"
            type="number"
            InputProps={{ inputProps: { min: 1, max: 20 } }}
            value={teamCount}
            onChange={(e) => this.setState({ teamCount: e.target.value })}
          />
        </div>
        <FormControl className="mt-15 mb-15">
          <h3 className="text-blue-bg" id="tournament_type">Tournament type</h3>
          <RadioGroup
            className="text-primary"
            row
            name="tournament_type"
            value={tournamentType}
            onChange={(e) => this.setState({ tournamentType: e.target.value })}
          >
            <FormControlLabel value="League" control={<Radio />} label="League" />
            <FormControlLabel value="Knockout" control={<Radio disabled />} label="Knockout" />
            <FormControlLabel value="Group" control={<Radio disabled />} label="Group" />
            <FormControlLabel value="Round robin" control={<Radio disabled />} label="Round robin" />
          </RadioGroup>
        </FormControl>
        <div className='flex-center'>
          <SecondaryButton className="me-15 cancel-btn" onClick={() => this.props.onClose()}>Cancel</SecondaryButton>
          <PrimaryButton onClick={() => this.createTournament()}>{!isLoading ? "Create" : <CircularProgress />}</PrimaryButton>
        </div>
      </div >
    );
  }
}

export default CreateTournamentModal;
