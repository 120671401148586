import React from "react";
import {
  Card,
  CardContent,
  CardActions,
  CardMedia,
  CircularProgress,
} from "@material-ui/core";
import CustomTextField from "../CustomMUI/CustomTextField";
import { PrimaryButton, SecondaryButton } from "../CustomMUI/CustomButtons";
import {
  sendHttpRequest,
  BASE_URL,
  isEmail,
  isContactNo,
} from "../../common/Common";
import logo from "../../assets/images/FieldR_Logo_Blue.jpg";
import { toast } from "react-toastify";
import { GoogleLogin } from "@react-oauth/google";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      emailOrContactNo: "",
      password: "",
      isLoading: false,
    };
    this.handleLogin = this.handleLogin.bind(this);
  }

  handleGoogleLoginSuccess = async (res) => {
    this.setState({ isLoading: true });

    const data = {
      googleToken: await res.credential,
    };

    sendHttpRequest(
      "POST",
      BASE_URL + "/api/auth/google-login",
      null,
      JSON.stringify(data)
    )
      .then((result) => {
        localStorage.setItem("loggedInUserToken", result.data.data.token);
        localStorage.setItem("loggedInUserId", result.data.data._id);
        this.setState({ isLoading: false });
        this.props.history.push("/home");
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        toast.error(error.response.data.message);
      });
  };

  handleGoogleLoginFailure = (res) => {
    toast.error(res.error);
  };

  handleLogin() {
    const { emailOrContactNo, password } = this.state;
    let params = {};

    if (!emailOrContactNo) {
      toast.error("Enter your email or your phone number");
      return;
    }
    if (!password) {
      toast.error("Enter Password");
      return;
    }

    if (emailOrContactNo.includes("@")) {
      if (!isEmail(emailOrContactNo)) {
        toast.error("Please Enter Valid Email Address");
        return;
      } else {
        params = {
          email: emailOrContactNo,
          password: password,
        };
      }
    } else {
      if (!isContactNo(emailOrContactNo)) {
        toast.error("Please Enter Valid Contact Number");
        return;
      } else {
        params = {
          contactNo: emailOrContactNo,
          password: password,
        };
      }
    }

    this.setState({ isLoading: true });

    sendHttpRequest(
      "POST",
      BASE_URL + "/api/auth/login",
      null,
      JSON.stringify(params)
    )
      .then((res) => {
        localStorage.setItem("loggedInUserToken", res.data.data.token);
        localStorage.setItem("loggedInUserId", res.data.data._id);
        this.setState({ isLoading: false });
        this.props.history.push("/home");
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        toast.error(err.response.data.message);
      });
  }

  render() {
    const { emailOrContactNo, password, isLoading } = this.state;
    return (
      <div>
        <div className="app-container">
          <div
            style={{ position: "relative", display: "flex", maxWidth: "92%" }}
          >
            <Card className="card">
              <CardContent>
                <CardMedia component="img" alt="FieldR Logo" src={logo} />
                <CustomTextField
                  label="Email / Contact No."
                  type="text"
                  value={emailOrContactNo}
                  onChange={(e) =>
                    this.setState({ emailOrContactNo: e.target.value })
                  }
                />
                <CustomTextField
                  label="Password"
                  type="password"
                  value={password}
                  onChange={(e) => this.setState({ password: e.target.value })}
                />
                <CardActions>
                  <PrimaryButton onClick={this.handleLogin}>
                    {isLoading ? <CircularProgress /> : "Login"}
                  </PrimaryButton>
                  <SecondaryButton
                    onClick={() => this.props.history.push("/signup")}
                  >
                    Register
                  </SecondaryButton>
                </CardActions>
                {/* <CardActions>
                  <GoogleLogin
                    onSuccess={this.handleGoogleLoginSuccess}
                    onError={this.handleGoogleLoginFailure}
                    useOneTap
                  />
                </CardActions> */}
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
