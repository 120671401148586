import React from 'react';
import './CustomMUI.css'
import { Button } from '@material-ui/core';

export function PrimaryButton(props) {
  return (
    <Button
      disabled={props.disabled}
      onClick={props.onClick}
      disableElevation
      variant='contained'
      fullWidth={props.fullWidth}
      type={props.type}
      className={props.className + ' primary-btn' || 'primary-btn'}
      
    >
      {props.children}
    </Button>
  );
};

export function SecondaryButton(props) {
  return (
    <Button
      disabled={props.disabled}
      onClick={props.onClick}
      disableElevation
      className={props.className + ' primary-hollow-btn' || 'primary-hollow-btn'}
      variant='outlined'
      startIcon={props.startIcon}   
               
    >
      {props.children}
      
    </Button>
  );
};

export function DeleteButton(props) {
  return (
    <Button
      onClick={props.onClick}
      disableElevation
      className={props.className + ' delete-btn' || 'delete-btn'}
      variant='outlined'
    >
      {props.children}
    </Button>
  );
};