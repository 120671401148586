import { TextField } from '@material-ui/core';
import React from 'react';

const CustomTextField = (props) => {
  return (
    <TextField
      fullWidth
      required
      variant='outlined'
      margin='normal'
      children={props.children}
      select={props.select}
      className={props.className}
      size={props.size}
      placeholder={props.placeholder}
      label={props.label}
      type={props.type}
      value={props.value}
      onChange={props.onChange}
      inputProps={props.inputProps}
      InputProps={props.InputProps}
    />
  );
};

export default CustomTextField;