import React from "react";
import { Switch, Route } from "react-router-dom";
import { BrowserRouter as Router } from "react-router-dom";
import Login from "./components/login/Login";
import Register from "./components/profile/Register";
import HomeScreen from "./components/home/HomeScreen";
import Scoresheet from "./components/score/Scoresheet";
import Scorecard from "./components/score/Scorecard";
import Profile from "./components/profile/Profile";
import Pool from "./components/pool/Pool";
import Pools from "./components/pool/Pools";
import Matches from "./components/match/Matches";
import Results from "./components/sessions/Results";
import AccessDenied from "./components/error/AccessDenied";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Team from "./components/team/Team";
import Tournaments from "./components/tournaments/Tournaments";
import Tournament from "./components/tournaments/Tournament";
import RegisterTeamTournament from "./components/tournaments/Registration/RegisterTeamTournament";
import TournamentSettings from "./components/tournaments/TournamentSettings";
import TournamentFixtures from "./components/tournaments/TournamentFixtures";
import RegisteredTeams from "./components/tournaments/Registration/RegisteredTeams";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      authenticated: true,
    };
  }

  render() {
    return (
      <div>
        <ToastContainer
          draggable={false}
          autoClose={5000}
          position={toast.POSITION.TOP_CENTER}
        />
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
          <Router>
            <Switch>
              <Route exact path="/" component={Login} />
              <Route exact path="/signup" component={Register} />
              {this.state.authenticated && (
                <Route exact path="/home" component={HomeScreen} />
              )}
            </Switch>
            <Route path="/pool/:poolId" component={Pool} />
            <Route path="/tournaments" component={Tournaments} />
            <Route path="/tournament/:tournamentId" exact component={Tournament} />
            <Route path="/tournament/register/:tournamentId" component={RegisterTeamTournament} />
            <Route path="/tournament/settings/:tournamentId" component={TournamentSettings} />
            <Route path="/tournament/registrations/:tournamentId" component={RegisteredTeams} />
            <Route path="/tournament/standings/:tournamentId" component={TournamentFixtures} />
            <Route path="/series-results/:seriesId" component={Results} />
            <Route path="/pools" component={Pools} />
            <Route path="/profile" component={Profile} />
            <Route path="/matches" component={Matches} />
            <Route path="/scoresheet/:matchId" component={Scoresheet} />
            <Route path="/match/:matchId" component={Scorecard} />
            <Route path="/team/:teamId" component={Team} />
            <Route path="/403" component={AccessDenied} />
          </Router>
        </GoogleOAuthProvider>
      </div>
    );
  }
}

export default App;
