import React from 'react';
import { TextField, MenuItem, DialogContent, Dialog } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { BASE_URL, checkIfUserLoggedIn, sendHttpRequest } from '../../common/Common';
import { toast } from 'react-toastify';
import CreateTeamModal from '../team/CreateTeamModal';
import CustomTextField from '../CustomMUI/CustomTextField';
import { PrimaryButton, SecondaryButton } from '../CustomMUI/CustomButtons';

class CreateMatchModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			overs: "",
			ground: "",
			ballType: "",
			pitchType: "",
			homeTeam: {},
			oppositionTeam: {},
			allTeams: [],
			showAddTeam: false,
			userId: ""
		};
	}

	componentDidMount() {
		checkIfUserLoggedIn(this.props.history);
		this.setState({ userId: localStorage.getItem('loggedInUserId') })
		this.getAllTeams()
	}

	async getAllTeams() {
		await sendHttpRequest("GET", BASE_URL + "/api/team/").then((res) => {
			this.setState({ allTeams: res.data.data });
		}).catch((error) => {
			toast.error(error.response.data.message)
		});
	}

	async createMatch() {
		const { overs, ground, ballType, pitchType, homeTeam, oppositionTeam, userId } = this.state

		function checkSimilarPlayer(arr1, arr2) {
			const set1 = new Set(arr1.map((player) => player._id));
			const set2 = new Set(arr2.map((player) => player._id));
			const result = [...set1].filter((value) => set2.has(value));
			return result.length
		}

		if (!ballType) {
			toast.error("Select a ball type")
			return
		}
		if (!pitchType) {
			toast.error("Select a pitch type")
			return
		}
		if (overs <= 0 || overs > 50) {
			toast.error("Select from 1 to 50 overs")
			return
		}
		if (!ground) {
			toast.error("Enter the ground name")
			return
		}
		if (!homeTeam) {
			toast.error("Select a home team")
			return
		}
		if (!oppositionTeam) {
			toast.error("Select a opposition team")
			return
		}
		if (homeTeam._id === oppositionTeam._id) {
			toast.error("Opposition & home team can't be same")
			return
		}
		if (checkSimilarPlayer(homeTeam.playerList, oppositionTeam.playerList) > 0) {
			toast.error("Opposition & home team can't have same players")
			return
		}

		let data = {
			ballType: ballType,
			overs: overs,
			ground: ground,
			pitchType: pitchType,
			teamA: homeTeam._id,
			teamB: oppositionTeam._id,
			createdBy: userId
		};

		this.setState({ isLoading: true });

		await sendHttpRequest("POST", BASE_URL + "/api/match", null, JSON.stringify(data)).then(() => {
			this.setState({ isLoading: false })
			toast.success("Match created successfully")
			this.props.onClose()
		}).catch((error) => {
			this.setState({ isLoading: false });
			toast.error(error.response.data.message)
		});
	}

	render() {
		const { ballType, overs, ground, pitchType, homeTeam, oppositionTeam, allTeams, showAddTeam } = this.state;
		const ballTypes = ["Tennis Ball", "Red Ball", "White Ball", "Pink Ball"]
		const pitchTypes = ["Indoor", "Matting", "Astra", "Turf", "Grass"]

		return (
			<div>
				<h2 className='text-primary text-center'>Create Match</h2>
				<div className='flex-between'>
					<CustomTextField
						select
						label="Ball type"
						className="me-15"
						value={ballType}
						onChange={(e) => this.setState({ ballType: e.target.value })}
					>
						{ballTypes.map((ballType, index) => (
							<MenuItem key={index} value={ballType}>{ballType}</MenuItem>
						))}
					</CustomTextField>
					<CustomTextField
						select
						label="Pitch type"
						value={pitchType}
						onChange={(e) => this.setState({ pitchType: e.target.value })}
					>
						{pitchTypes.map((pitchType, index) => (
							<MenuItem key={index} value={pitchType}>{pitchType}</MenuItem>
						))}
					</CustomTextField>
				</div>
				<CustomTextField
					label="No. of Overs"
					type="number"
					InputProps={{ inputProps: { min: 1, max: 50 } }}
					value={overs}
					onChange={(e) => this.setState({ overs: e.target.value })}
				/>
				<CustomTextField
					label="Ground"
					type="text"
					value={ground}
					onChange={(e) => this.setState({ ground: e.target.value })}
				/>
				<Autocomplete
					options={allTeams}
					getOptionLabel={(option) => option.name}
					value={homeTeam}
					onChange={(event, newValue) => this.setState({ homeTeam: newValue })}
					renderInput={(params) => (
						<TextField
							{...params} required fullWidth
							style={{ marginTop: "20px" }}
							label="Home Team"
							variant="outlined"
						/>
					)}
				/>
				<Autocomplete
					options={allTeams}
					getOptionLabel={(option) => option.name}
					value={oppositionTeam}
					onChange={(event, newValue) => this.setState({ oppositionTeam: newValue })}
					renderInput={(params) => (
						<TextField
							{...params} required fullWidth
							style={{ marginTop: "20px" }}
							label="Opposition Team"
							variant="outlined"
						/>
					)}
				/>
				<center className="my-15">
					<SecondaryButton onClick={() => this.setState({ showAddTeam: true })}>No team? Create one</SecondaryButton>
				</center>
				<div className="flex-around my-15">
					<SecondaryButton className="cancel-btn" onClick={() => this.props.onClose()}>Cancel</SecondaryButton>
					<PrimaryButton onClick={() => this.createMatch()}>Create</PrimaryButton>
				</div>
				<Dialog fullWidth open={showAddTeam} onClose={() => this.setState({ showAddTeam: false })} >
					<DialogContent>
						<CreateTeamModal onClose={() => { this.setState({ showAddTeam: false }); this.getAllTeams() }} />
					</DialogContent>
				</Dialog>
			</div >
		);
	}
}
export default CreateMatchModal;